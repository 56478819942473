export const environment = {
  production: false,
  url: "https://api.fmx.dev.rtsapps.co.za/RCWebservice/api",
  dashUrl: "https://fmx-dashboard.dev.rtsapps.co.za/",
  fleetOverviewSocket: "https://fleetsocket.devubuntu.rtsapps.co.za",
  fmxStatusSocket: "https://statussocket.devubuntu.rtsapps.co.za",
  fmxDashboardService: "https://fmxdashdata.devubuntu.rtsapps.co.za/",
  connectAPI: "https://fmx-connect-api.devubuntu.rtsapps.co.za",
  roleService: "https://roleapi.devubuntu.rtsapps.co.za/",
  qsmackerSummarySocket: 'wss://fmx-connect-api.devubuntu.rtsapps.co.za/ws/summary',
  mlapLogoutUrl: "https://mlap.auth.dev.icecloud.co/logout"
};
