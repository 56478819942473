import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private loginService: LoginService
  ) {

  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise(async (resolve, reject) => {
        const currentUser = await this.loginService.isLoggedIn();
        if (currentUser) {
            if (state.url === '/home' || state.url === '/access') {
              // all may access so return true
              resolve(true);
              return;
            }
            const applications = await this.loginService.getUserApplications();
            const authorised =  _.some(applications, a => {return state.url.includes(a.route) })
            if (authorised) {
              resolve(true);
              return;
            }

            this.router.navigate(['/access']);
            return;
        }
        // not logged in so redirect to login page with the return url
        this.loginService.logout({ queryParams: { returnUrl: state.url }});
        resolve(false);
      });

  }

}
